<template>
  <div
    class="py-4 active-call-modal"
    ref="callPane"
    :style="style"
    style="position: fixed"
  >
    <!-- Active Call -->
    <div class="call-pane-header" id="call-pane-header">
      <v-card
        color="accent"
        dark
        class="px-4 pt-8 pb-4 active-call-box"
        v-show="!showDTMFPad"
      >
        <!-- <v-row v-if="dialerStore.callDirection === 'incoming' && dialerStore.dialerState.showAnswerButton"> -->
        <v-row
          v-if="
            dialerStore.callDirection === 'incoming' &&
            dialerStore.dialerState.showAnswerButton
          "
          class="px-3"
        >
          <v-col cols="2" class="px-0">
            <v-avatar class="active-avatar" size="40">{{
              getInitials(getContactName(dialerStore.dialerState.peer) || "U")
            }}</v-avatar>
          </v-col>
          <v-col cols="5" class="px-0">
            <small class="call-type"
              >{{ dialerStore.callDirection }} call...</small
            >
            <p
              class="incoming-number"
              v-if="dialerStore.dialerState.peer == '999'"
            >
              {{ "Website Caller" }}
            </p>
            <p class="incoming-number" v-else>
              {{ getContactName(dialerStore.dialerState.peer) }}
            </p>
          </v-col>
          <v-col cols="5" class="d-flex justify-end">
            <button class="call-end-btn mr-2" @click="endActiveCall">
              <img
                src=" ../../assets/Images/icons/end-call.svg
                  "
                width="29"
              />
            </button>
            <button class="call-answer-btn" @click="answerIncomingCall">
              <img
                src="../../assets/Images/icons/answer-call.svg"
                width="16"
              /></button
          ></v-col>
        </v-row>
        <div v-else>
          <div class="d-flex justify-center">
            <img src="../../assets/Images/icons/call-avatar.svg" />
          </div>
          <p
            class="accent-text text-center active-number cursor-pointer mt-2"
            @click="copy(dialerStore?.dialerState.peer)"
          >
            {{
              dialerStore?.dialerState.peer === "999"
                ? "Widget Caller"
                : getContactName(dialerStore?.dialerState.peer)
            }}
          </p>
          <div class="text-center mt-1 mb-3">
            <p
              :class="
                dialerStore.dialerState.callDurationFormatted === '00:00:00'
                  ? 'call-status-desc'
                  : 'call-number-status'
              "
            >
              {{
                dialerStore.dialerState.callDurationFormatted === "00:00:00"
                  ? dialerStore.dialerState.callStatus
                  : dialerStore.dialerState.callDurationFormatted
              }}
            </p>
          </div>

          <div>
            <div class="d-flex justify-center ga-5 mb-3 mt-7 mx-1">
              <!-- Free users cant transfer calls -->

              <div class="d-flex flex-column">
                <button
                  class="call-btn"
                  :class="{ active: dialerStore.dialerState.isMuted }"
                  @click="toggleMuteCall"
                >
                  <img
                    src="../../assets/Images/icons/mute-icon.svg"
                    width="13"
                  />
                </button>
                <small class="text-center mt-1 call-action-desc">{{
                  dialerStore.dialerState.isMuted ? "Unmute" : "Mute"
                }}</small>
              </div>
              <div class="d-flex flex-column">
                <button
                  class="call-btn"
                  :class="{ active: dialerStore.dialerState.isHeld }"
                  @click="toggleHoldCall"
                >
                  <img src="../../assets/Images/icons/pause.svg" width="17" />
                </button>
                <small class="text-center mt-1 call-action-desc">{{
                  dialerStore.dialerState.isHeld ? "Unhold" : "Hold"
                }}</small>
              </div>
              <div
                class="d-flex flex-column align-center"
                v-if="dialerStore.showCallInfo"
              >
                <!--Change to incoming-->
                <button class="call-btn" @click="openDialerSideModal">
                  <img src="../../assets/Images/icons/note.svg" width="19" />
                </button>
                <small class="text-center mt-1 call-action-desc">
                  Show Info</small
                >
              </div>
              <div
                class="d-flex flex-column pointer"
                @click="showDTMFPad = !showDTMFPad"
                v-if="dialerStore.showCallInfo"
              >
                <img src="../../assets/Images/icons/dial-pad.svg" width="40" />
                <small class="text-center mt-1 ml-n1 call-action-desc"
                  >Keypad</small
                >
              </div>
              <div class="d-flex flex-column">
                <button class="call-end-btn" @click="endActiveCall">
                  <img
                    v-if="dialerStore.dialerState.callDurationSeconds"
                    src="../../assets/Images/icons/end-call.svg"
                    width="30"
                  />
                  <img
                    v-else
                    src="../../assets/Images/icons/cancel.svg"
                    width="18"
                  />
                </button>
                <small class="text-center mt-1 call-action-desc">{{
                  dialerStore.dialerState.callDurationSeconds ? "End" : "Cancel"
                }}</small>
              </div>
            </div>
          </div>
        </div>
        <!-- Free users cant transfer calls -->
        <div
          class="team-transfer-card text-white px-3 py-2"
          v-if="
            !userStore.currentUserBusinessLevel?.free_trial_data
              ?.is_free_trial_user && dialerStore.showCallInfo
          "
        >
          <div class="d-flex align-center justify-space-between">
            <p class="transfer-text text-white">Transfer Call</p>
            <i
              class="icon icon-arrow-down-1 pointer"
              :class="
                toggleTransferModal ? 'icon-arrow-up-2' : 'icon-arrow-down-1'
              "
              @click="toggleTransferModal = !toggleTransferModal"
            ></i>
          </div>
        </div>
        <div
          v-if="toggleTransferModal"
          class="mt-3 bg-white team-transfer-card pt-1"
        >
          <div
            v-if="teamStore?.getRecieversUnderABusiness?.length > 0"
            class="receiver-list-card"
          >
            <div
              class="d-flex align-center justify-space-between pointer team-member-list px-3 py-2"
              v-for="(teamMember, i) in teamStore?.getRecieversUnderABusiness"
              :key="i"
            >
              <p class="transfer-text">
                {{ teamMember?.receiver_name }}
              </p>
              <v-btn
                variant="plain"
                flat
                color="#101928"
                height="20"
                width="50"
                @click="transferCallToTeamMember(teamMember?.line?.username)"
                >Transfer</v-btn
              >
            </div>
          </div>
          <small v-else class="px-1 pb-1"
            >No available team member to transfer call to</small
          >
        </div>
        <CallFeedbackbox
          class="mt-3"
          v-if="dialerStore.showFeedback && utilsStore.totalCalls < 1"
        />
      </v-card>
    </div>
    <div class="mt-2" v-if="showDTMFPad">
      <v-card
        color="accent"
        dark
        class="px-4 pt-8 pb-4 cursor-default"
        rounded="lg"
      >
        <div class="">
          <div class="d-flex justify-space-between mx-7 px-3 dialer-title">
            <div class="mb-3 d-flex align-center ga-1 mt-2">
              <img
                src="../../assets/Images/icons/calltype/call-outgoing.svg"
                alt=""
                width="12"
              />
              <p class="call-number-status dial-pad-timer">
                {{
                  dialerStore.dialerState.callDurationFormatted === "00:00:00"
                    ? dialerStore.dialerState.callStatus
                    : dialerStore.dialerState.callDurationFormatted
                }}
              </p>
            </div>
            <div class="mb-3 d-flex align-center ga-1 mt-2">
              <img
                src="../../assets/Images/icons/return.svg"
                alt=""
                width="12"
              />
              <p
                class="call-number-status return-text pointer"
                @click="showDTMFPad = !showDTMFPad"
              >
                Return to call
              </p>
            </div>
          </div>
          <div class="dtmf-display">
            <div class="dtmf-input">
              <div class="dtmf-input-inner">
                {{ DTMFInput.join("") }}
              </div>
            </div>
            <v-icon class="dtmf-clear" @click="DTMFInput.pop()"
              >mdi-backspace-outline</v-icon
            >
          </div>
          <div class="dtmf-pads">
            <div
              v-for="i in [1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#']"
              :key="i"
              class="dtmf-pad"
              @mousedown="DTMFClick(i)"
            >
              <div class="dial-pad-size">
                {{ i }}
                <span class="dtmf-pad-char">{{ dtmfPadChars[i] }}</span>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script setup lang="ts">
import { inject, onMounted, ref, computed } from "vue";
import { useDialerStore } from "../../stores/dialer.store";
import { Contact } from "../../helpers/types/contact";
import { PressoneInstance } from "../../plugins/dialer";
import { useNotesStore } from "../../stores/notes.store";

import {
  answerCall,
  endCall,
  holdCall,
  muteCall,
  playDTMF,
  sendDTMF,
  transferCall,
  // transferCall,
} from "../../helpers/dialer";

import { getInitials, copyText } from "../../helpers";
import { useTeamStore } from "../../stores/team-member.store";
import { notify } from "@kyvg/vue3-notification";
import logger from "../../helpers/logger";

import { useDraggable } from "@vueuse/core";
import { useUsersStore } from "../../stores/users.store";
import CallFeedbackbox from "./CallFeedbackbox.vue";
import { useUtilsStore } from "../../stores";

const callPane = ref(null);

const dialerStore = useDialerStore();
const teamStore = useTeamStore();
const noteStore = useNotesStore();
const userStore = useUsersStore();
const utilsStore = useUtilsStore();

const getPressoneInstance: PressoneInstance | any = inject(
  "getPressoneInstance"
);

const getOngoingCallBusinessNumberId = computed(() => {
  return dialerStore.getActiveCallBusinessNumber;
});

const openDialerSideModal = () => {
  try {
    dialerStore.dialerModal = true;
    dialerStore.showDialerModal = false;
    dialerStore.activeCallToEdit =
      dialerStore.savedContacts.data?.find(
        (contact: any) => contact.number === dialerStore.dialerState.peer
      ) || {};

    // if (dialerStore.callDirection === "incoming") {
    const businessNumberId: string | number | null =
      getOngoingCallBusinessNumberId.value || null;
    noteStore.activeCallNote = {
      call_id:
        dialerStore.callDirection === "incoming"
          ? getPressoneInstance()?.getOngoingCallSession()?._request?.headers[
              "X-Call-Sid"
            ][0].raw
          : dialerStore.getOutgoingXCallID,
      contact_number: dialerStore.dialerState.peer,
      business_number_id: businessNumberId!,
      id: undefined,
      note: "",
    };
    // }
  } catch (error) {
    logger.error(error, "Get Info");
  }
};
const toggleTransferModal = ref(false);
const DTMFInput = ref<(number | string)[]>([]);
const showDTMFPad = ref(false);
const dtmfPadChars = ref<any>({
  1: "~",
  2: "abc",
  3: "def",
  4: "ghi",
  5: "jkl",
  6: "mno",
  7: "pqrs",
  8: "tuv",
  9: "wxyz",
  "*": "+",
  0: "",
  "#": "",
});
// const openDialerSideModal = () => {
//   dialerStore.dialerModal = true
//   dialerStore.activeCallInstanceKey = getPressoneInstance?.getActiveCallInstanceKey()
// }

const endActiveCall = () => {
  if (getPressoneInstance) endCall(getPressoneInstance);
};
const answerIncomingCall = () => {
  if (getPressoneInstance) answerCall(getPressoneInstance);
};

const toggleHoldCall = () => {
  if (getPressoneInstance)
    holdCall(dialerStore.dialerState, getPressoneInstance);
};
const toggleMuteCall = () => {
  if (getPressoneInstance)
    muteCall(dialerStore.dialerState, getPressoneInstance);
};
const transferCallToTeamMember = (extension_code: any) => {
  if (getPressoneInstance) transferCall(getPressoneInstance, extension_code);
};

const getContactName = (number: string) => {
  // number = validateMobile(number); // remove prefix
  let contacts = dialerStore.savedContacts.data;
  if (Array.isArray(contacts)) {
    let contact = contacts.find(
      (contact: Contact) => contact?.number === number
    );
    if (contact) {
      return contact?.name;
    }
  }
  return number;
};
const DTMFClick = (input: number | string) => {
  DTMFInput.value.push(input);
  if (getPressoneInstance) playDTMF(getPressoneInstance, input);
  if (getPressoneInstance) sendDTMF(getPressoneInstance, input);
};
const copy = (text: string) => {
  copyText(text);
  notify({
    text: "Phone number copied",
    type: "sucess",
  });
};
// get the position for the dialer in the agent dashboard. it should be from the second quarter
const viewportWidth = ref(
  window.innerWidth || document.documentElement.clientWidth
);

const getAgentViewPositionForDialer = computed(() => {
  return viewportWidth.value / 4 + 1;
});

const x_axis_position = computed(() => {
  return dialerStore.callSource.toLowerCase() === "call_queue"
    ? getAgentViewPositionForDialer.value
    : viewportWidth.value - 400;
});
const y_axis_position = computed(() => {
  return dialerStore.callSource.toLowerCase() === "call_queue" ? 70 : 66;
});
const { style } = useDraggable(callPane, {
  initialValue: {
    x: x_axis_position.value,
    y: y_axis_position.value,
  },
});
onMounted(async () => {
  window.addEventListener(
    "resize",
    () => (viewportWidth.value = window.innerWidth)
  );
});
</script>
<style scoped>
.team-transfer-card {
  border: 1px solid #344054;
  background: #78788052;
  border-radius: 8px;
}
.transfer-text {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 23.2px;
  color: #101928;
}
.team-member-list:hover {
  background: #f0f0f0;
}
.dial-pad-timer {
  color: #34c759;
}
.dialer-title {
  background: #1d2739a8;
  border: 1px solid #34405499;
  border-radius: 300px;
}
.return-text {
  color: #98a2b3;
}
/* Dial pad css */
.dtmf-pads {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}
/* .dial-pad-size{
  background: #78788052;
  box-shadow: 0px 1px 2px 0px #66718561;
  box-shadow: 0px 1px 1px 0px #0000001a;
  box-shadow: 1px 0px 9.8px 0px #101928 inset;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
} */

.dtmf-pad {
  flex-basis: 33.3333%;
  padding: 16px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #f7f9fc;
}

.dtmf-pad:hover {
  background: rgba(51, 51, 51, 0.05);
}

.dtmf-pad-char {
  text-transform: uppercase;
  display: block;
  text-align: center;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.12em;
  color: #98a2b3;
}

.dtmf-display {
  display: flex;
  padding: 24px 16px 16px;
}

.dtmf-input {
  font-size: 24px;
  letter-spacing: 2px;
  text-align: center;
  flex-grow: 1;
  user-select: none;
}

.dtmf-input-inner {
  overflow: hidden;
}

.dtmf-clear {
  font-size: 24px;
  cursor: pointer;
}
.receiver-list-card {
  height: 150px;
  overflow: auto;
}
</style>
