export const BASE_API =
    localStorage.getItem("baseUrl") ?? "https://api.pressone.co";

export const PINIA_PERSIST_OPTIONS = {
    storage: localStorage,
};
//
export const AUTH_EXCLUDE_LIST = [
    "/api/login/",
    "/api/auth/token/",
    "/api/auth/token/refresh/",
    "/api/payments/subscription-plan/",
    "/api/recommendation-form/",
    "/api/users/register/",
];

export const predefinedGradients = [
    "linear-gradient(180deg, #FF8268 0%, rgba(255, 173, 156, 0.76) 100%)",
    "linear-gradient(147.58deg, #2EC97F 12.62%, rgba(0, 228, 119, 0.78) 92.01%)",
    "linear-gradient(151.34deg, #37BAD7 -7.94%, rgba(0, 209, 255, 0.79) 82.33%)",
    "linear-gradient(156.37deg, #EA61C4 4.95%, rgba(255, 165, 230, 0.82) 87.83%)",
    "linear-gradient(146.84deg, #66B9CB 9.21%, rgba(147, 235, 255, 0.8) 90.12%)",
    "linear-gradient(147.48deg, #715AFF -5.35%, rgba(186, 174, 255, 0.85) 80.53%)",
    "linear-gradient(153.43deg, #11BEAC 0%, rgba(91, 231, 217, 0.94) 83.33%)",
    "linear-gradient(160.07deg, #FF5A78 3.66%, rgba(255, 180, 194, 0.91) 86.7%)",
];

// Api switch
const currentRoute = window.location.host;
export const STAGING_BASE_API = "https://gaia-api.staging.cloud.pressone.co";
export const INTERNAL_LIVE_BASE_API =
    "https://pressone-internal-live.herokuapp.com";
export const PRODUCTION_BASE_API = "https://api.pressone.co";

export const TELEPHONY_BASE_API = "https://telephony.api.pressone.co";

export const IS_PROD = currentRoute === "web.pressone.africa";
export const IS_STAGING = BASE_API === STAGING_BASE_API;
export const IS_INTERNAL = BASE_API === INTERNAL_LIVE_BASE_API;

export const ENVIRONMENT_OPTIONS = [
    { label: "Staging", key: "staging", value: STAGING_BASE_API },
    {
        label: "Deprecated[Internal Live]",
        key: "internal_live",
        value: INTERNAL_LIVE_BASE_API,
    },
    { label: "Production", key: "production", value: PRODUCTION_BASE_API },
];

// Engage analytics
export const ENGAGE_PUBLIC_KEY = "04c36dc3bf6d142c7e31c4bd2bd04d6b";
export const ENGAGE_PRIVATE_KEY = "2213c498f11fb08c7177f33060ec4056";

// Digital Ocean Credentials
export const DIGITAL_OCEAN_ACCESS_KEY = "DO00XDHCEU47RHMX32V8";
export const DIGITAL_OCEAN_SECRET_ACCESS_KEY =
    "BIIypGteKinUoIgwyQKjLuoINx1w38/P6vSr/oePkpk";
export const DIGITAL_OCEAN_REGION = "fra1";
export const DIGITAL_OCEAN_ENDPOINT = "https://fra1.digitaloceanspaces.com";
export const STAGING_DIGITAL_OCEAN_BUCKET = "pressone-staging";
export const PROD_DIGITAL_OCEAN_BUCKET = "pressone-prod";
