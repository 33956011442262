<template>
  <v-app>
    <v-main>
      <ActiveCall v-if="showDialer" v-show="dialerStore.showDialerModal" />
      <notifications class="notification" />

      <router-view />
      <Survey
        v-if="
          businessStore.businesses?.length &&
          !businessStore.activeProfile?.has_submitted_weekly_survey &&
          authStore.isAuthenticated
        "
      />
      <!-- TODO Add survey check -->
      <SurveyToggleCard
        class="feedback-card"
        v-if="
          authStore.isAuthenticated &&
          businessStore.businesses?.length &&
          utilsStore.showSurveyCard
        "
        @click="utilsStore.showSurveyModal = true"
      />
      <v-dialog
        v-model="showPostOnboarding"
        class="d-flex justify-center align-center"
        width="500"
        persistent
      >
        <OnboardingUpdate @closeModal="showPostOnboarding = false" />
      </v-dialog>
      <v-dialog
        v-model="utilsStore.showVerifyModalForCall"
        class="d-flex justify-center align-center"
        width="400"
        persistent
      >
        <ShowVerifyModalForCall />
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import ActiveCall from "./components/Shared/ActiveCall.vue";

import {
  defineComponent,
  onUnmounted,
  onMounted,
  inject,
  watch,
  ref,
} from "vue";
import {
  useAuthStore,
  useDialerStore,
  useUsersStore,
  useNumberStore,
  useActivityStore,
  useUtilsStore,
  useBusinessStore,
  useAddonStore,
} from "./stores";
import { useRouter, useRoute } from "vue-router";
import { PressoneInstance } from "./plugins/dialer";
import { PressOne } from "@pressone/dialer";
import {
  handleFetchUserDetails,
  useCallQueue,
  getPendingNumberPayment,
  getPermissions,
  fetchAllVideo,
  useConnectPhoneToSip,
  getSubscriptionStatus,
  fetchIndividualCallLength,
  hasMadeTestCall,
} from "./helpers";
import logger from "./helpers/logger";
import { updateSessionActivity } from "./helpers/queries/teamMembers";
import { computed } from "vue";
import { useFreeLiveCallRedirection } from "./helpers/developer-program";
import { notify } from "@kyvg/vue3-notification";
import OnboardingUpdate from "./components/Shared/banners/OnboardingUpdate.vue";
import ShowVerifyModalForCall from "./components/Shared/banners/ShowVerifyModalForCall.vue";

import Survey from "./components/Shared/Survey.vue";
import SurveyToggleCard from "./components/Shared/SurveyToggleCard.vue";
export default defineComponent({
  components: {
    ActiveCall,
    OnboardingUpdate,
    ShowVerifyModalForCall,
    SurveyToggleCard,
    Survey,
  },
  setup() {
    const numbersStore = useNumberStore();
    const businessStore = useBusinessStore();
    const userStore = useUsersStore();
    const dialerStore = useDialerStore();
    const activityStore = useActivityStore();
    const { isAuthenticated } = useAuthStore();
    const addonStore = useAddonStore();
    const authStore = useAuthStore(); // realize this is more reactive than the one on top. this is better
    // const contactStore = useContactStore();

    const showPostOnboarding = ref(false);

    const { handleTokenRedirection } = useFreeLiveCallRedirection();

    const showDialer = computed(() => {
      return (
        authStore.isAuthenticated &&
        !dialerStore.isCallQueue &&
        (dialerStore.callSource.toLowerCase() !== "call_queue" ||
          callQueue.value.length < 1)
      );
    });

    const router = useRouter();
    const route = useRoute();
    const utilsStore = useUtilsStore();
    const engagementFormStatus = ref<boolean | null>(null);
    const { callQueue } = useCallQueue;

    const previousRoutePath = router?.options?.history?.state?.back;
    const setPressoneInstance = inject<
      (data: PressoneInstance | PressOne | void) => void
    >("setPressoneInstance");
    const handleOnlineStatusChange = async () => {
      try {
        if (setPressoneInstance)
          setPressoneInstance(await dialerStore.connectSipToPhone());
      } catch (error) {
        logger.error(error, "Error handling online status change:");
      }
    };

    // End Watch for change in connection
    const { reconnectPhoneAfterDiconnection, disconnectPhoneWhenOffline } =
      useConnectPhoneToSip();

    const openEngagementModal = () => {
      if (businessStore.activeProfile) {
        if (
          !businessStore.activeProfile?.business?.has_filled_engagement_form &&
          route.path !== "/managers/engagement" &&
          (userStore.user_role === "owner" || userStore.user_role === "manager")
        ) {
          setTimeout(() => {
            showPostOnboarding.value = true;
          }, 10000);
        }
      } else return;
    };

    const fetchCallLogsForAgents = async () => {
      try {
        const response = await fetchIndividualCallLength();
        utilsStore.totalCalls = response?.data?.total;
      } catch (error) {
        if (
          typeof error === "string" &&
          error.toString() !==
            "You do not have permission to perform this action."
        )
          logger.error(error, "Error Fetching Logs");
      }
    };

    const fetchAllCallLogs = async () => {
      try {
        if (
          userStore.user_role === "owner" ||
          userStore.user_role === "manager"
        ) {
          const resp = await hasMadeTestCall();
          utilsStore.madeFirstCall = resp?.data?.data?.some(
            (call: any) => call.is_answered === true
          );
        }
      } catch (error) {
        if (
          typeof error === "string" &&
          error.toString() !==
            "You do not have permission to perform this action."
        )
          logger.error(error, "Error Fetching Lossgs");
      }
    };

    onMounted(async () => {
      useCallQueue.isFetching.value = true;
      if (isAuthenticated) {
        try {
          openEngagementModal();
          // if (userStore.user_role == "agent") {
          //   useCallQueue.isFetching.value = true;

          //   const callQueues = await getAgentCallQueue(
          //     businessStore.currentUserId
          //   );

          //   if (!callQueues.data?.length) {
          //     await createCallQueue({
          //       created_by_id: businessStore.currentUserId,
          //       name: `call-queue-${businessStore.currentUserId}`,
          //       agent_id: businessStore.currentUserId,
          //       limit: 0,
          //     });
          //   }
          //   useCallQueue.listCallQueueAgent();

          //   await listContacts().then((res) => {
          //     contactStore.allContacts = res.contacts.data;
          //   });
          // }

          engagementFormStatus.value =
            userStore?.currentUserBusinessLevel?.has_filled_engagement_form;

          if (userStore.user_role === "agent" && !engagementFormStatus.value) {
            router.push("/agents/engagement");
          }

          const pendingSetupResponse = await getPendingNumberPayment();
          const pendingNumberSetup = pendingSetupResponse.data;

          utilsStore.setSetUpPayload(pendingNumberSetup.data);
          if (pendingNumberSetup?.data?.length) {
            userStore.user_role = "owner";
            router.push("/managers/provision-number");
          }

          await handleFetchUserDetails();

          businessStore.getBusiness(); // only update manager for now
          await numbersStore.getUserPhonesFromBusiness();
          userStore.permissions = await getPermissions();

          if (businessStore.activeProfile) {
            utilsStore.runSurveyCheck();
          }

          activityStore.connectDB();
          // moved the connection logic up to make it fast and prevent it from being delayed by other async calls
          if (
            (numbersStore?.numbers?.length !== 0 &&
              previousRoutePath !== "/otp") ||
            userStore.currentUserBusinessLevel?.free_trial_data
              ?.is_free_trial_user
          ) {
            const status = await getSubscriptionStatus(); // get subscription status after business is fetched to fix 404 issue
            numbersStore.subscriptionStatus = status as string;

            // get all number
            if (numbersStore?.subscriptionStatus === "past_due") {
              notify({
                type: "error",
                text: "Account restricted due to outstanding payment",
              });
              return;
            }
            handleOnlineStatusChange(); // run initially on page mount
            window.addEventListener("online", handleOnlineStatusChange); // run everytime there is a network change
            window.addEventListener("offline", disconnectPhoneWhenOffline); // // run everytime there is a network change

            setInterval(() => {
              reconnectPhoneAfterDiconnection();
            }, 15000);
          }
          if (
            userStore.user_role === "owner" ||
            userStore.user_role === "manager"
          ) {
            // only allow managers or owner to access this endpoint
            await addonStore.fetchSubscriptionTerm();
          } // fix  error for empty term}
          await addonStore.fetchAllAddons();
          await addonStore.fetchSubscribedAddons();

          await handleUpdateSessionActivity();
          // const getSummary = await fetchCallSummary("this_year"); // revert to this when agent query is avaialable
          // utilsStore.totalCalls = getSummary?.data?.total_call;
          // activityStore.listenDB();

          if (numbersStore.numbers?.length) {
            // only for people with receivers
            await fetchCallLogsForAgents();
            await fetchAllCallLogs();
          }

          await dialerStore.fetchContact();
          const demoVideosResponse = await fetchAllVideo(
            userStore.user_role === "agent" ? "agents" : "managers"
          );
          utilsStore.youtubeVideos = demoVideosResponse.data;
          // get subscription status
        } catch (error) {
          logger.error(
            error,
            "An error occured with asynchronous calls on Mounted"
          );
        }
      }
    });

    watch(
      // Watch the route for token to redirect free users
      () => route.query,
      async (to: any) => {
        if (to.token) {
          try {
            await handleTokenRedirection(to.token);
          } catch (error) {
            logger.error(error, "Redirect from landing page");
          }
        }
      }
    );

    const handleUpdateSessionActivity = async () => {
      await updateSessionActivity();
      setInterval(async () => {
        await updateSessionActivity();
      }, 300000);
    };

    onUnmounted(async () => {
      if (isAuthenticated) {
        window.removeEventListener("online", handleOnlineStatusChange);
        window.removeEventListener("offline", handleOnlineStatusChange);
      }
    });
    return {
      dialerStore,
      activityStore,
      isAuthenticated,
      authStore,
      userStore,
      businessStore,
      callQueue,
      showDialer,
      showPostOnboarding,
      utilsStore,
    };
  },
});
</script>

<style scoped>
.feedback-card {
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
