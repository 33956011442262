import * as Sentry from "@sentry/vue";
import { IS_PROD } from "./constants";

const errorToSentrySeverity: Record<string, string> = {
  warn: "warning",
  info: "info",
  error: "error",
  debug: "debug",
};
const exemptErrors: string[] = [
  "Unhandled Promise Rejection: Disconnect aborted.",
  "Unhandled Promise Rejection: WebSocket closed wss://fs1.pressone.co:7443 (code: 1006)",
  "Uncaught Error: Registerer terminated. Unable to register.",
];
const log = (
  type: any,
  obj?: any,
  message?: string
) => {
  // @ts-ignore
  const logFunction = console[type];
  const logArgs = [...(message ? [message] : []), ...(obj ? [obj] : [])];
  logFunction(...logArgs);

  if (type === "error") {
    // Sentry.captureMessage(message ?? JSON.stringify(obj), errorToSentrySeverity[type]);
    if (exemptErrors.includes(message!)) {
      log("warn", obj, message);
      return;
    }

    // @ts-ignore
    if (IS_PROD) Sentry.captureException(obj, errorToSentrySeverity[type]);
    else {
      // Log to console.error in non-production environments. This to allow us see the error on local and fix before it gets to prod
      console.error("Non-production environment:", message, obj);
    }
  }
};

export default {
  log: (obj?: any, message?: string) => log("log", obj, message),
  info: (obj?: any, message?: string) => log("info", obj, message),
  error: (obj?: any, message?: string) => log("error", obj, message),
  warn: (obj?: any, message?: string) => log("warn", obj, message),
  debug: (obj?: any, message?: string) => log("debug", obj, message),
};


